$background-primary: #fdf6e9 !default;
$background-fill-01: #e7e7e7 !default;
$background-fill-02: #f9efe0 !default;
$text-primary: #144a3d !default;
$text-secondary: #463643 !default;
$text-03: #652d38 !default;
$text-light: #6d665c !default;
$light-border-color: #d1c1b5 !default;
$border-color: #9e8e82 !default;
$border-color-01: #E5E5E5 !default;
$color-primary: #ff7b44 !default;
$color-primary-disabled: #f79e73 !default;
$color-primary-hover: #fa651e !default;
$color-primary-dark: #cc490a !default;
$color-error: #ff5757 !default;
$color-sand: #fff3e1 !default;
$color-footer-grey: #faf7eb !default;
$default-green: #24493E;

$purple: #3f2b3e;
