@import './colors.scss';

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #ddd !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-550: #989898 !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$blue: #4582ec !default;
$indigo: #6610f2 !default;
$purple: rgb(58, 33, 58) !default;
$purple2: #463643 !default;
$pink: #e83e8c !default;
$red: #d9534f !default;
$orange: black !default;
$yellow: #f0ad4e !default;
$green: #02b875 !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;
$dark-brown: #652d38 !default;
$dark-green: #004738 !default;

$primary: $color-primary !default;
$secondary: $purple2 !default;
$success: #144a3d !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: #ff4844 !default;
$light: $gray-100 !default;
$dark: $gray-800 !default;
$success-light: lighten($success, 20%) !default;

$input-border: #d1c1b5;
$input-border-focus: #9e8e82;

$dark-green: #2a493e;
$orange: #ee814d;
