@import '../../../../styles/colors.scss';
@import '../../../../styles/bootstrap-imports.scss';

.recipeItem {
  width: 90%;
  position: relative;
  margin: 0 auto;
  margin-bottom: 20px;
  background-color: white;
  color: $text-secondary;
  border-radius: 20px;
  text-align: center;
  min-height: 473px;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  &__image {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    width: 100%;
  }

  &__header {
    font-size: 26px;
    padding: 50px 0 0 0;
    font-weight: 700;
  }

  &__description {
    padding: 10px 20px;
    font-weight: 500;
    font-size: 14px;
  }

  &__iconWrapper {
    height: 0px;
  }

  &__icon {
    margin-top: -26px;
    height: 48px;

    @include media-breakpoint-up(lg) {
      margin-top: -35px;
      height: 70px;
    }
  }

  &__link {
    font-size: 14px;
  }
}

@include media-breakpoint-down(md) {
  .recipeItem {
    width: 280px;
    margin: 10px 10px 18px 10px;
    display: inline-flex !important;

    &__header {
      padding-top: 40px;
      font-size: 24px;
      line-height: 20px;
    }
    &__description {
      font-size: 14px;
      line-height: 20px;
    }
  }
}
