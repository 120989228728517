@import "../../styles/bootstrap-colors.scss";
@import '../../styles/colors.scss';
@import '../../styles/bootstrap-imports.scss';

@mixin rotation($rotation) {
  transform: rotate($rotation);
  -ms-transform: rotate($rotation);
  -moz-transform: rotate($rotation);
  -webkit-transform: rotate($rotation);
}

.howItWorks {
  height: 500px;

  @media (max-width: 430px) {
    height: 400px;
  }
  @media (max-width: 390px) {
    height: 350px;
  }
  @media (max-width: 330px) {
    height: 300px;
  }

  position: relative;
  overflow: visible;

  &__dog {
    position: absolute;
    max-width: 90%;
    transition: opacity .15s ease-in-out;

    &:nth-child(1) {
      @include rotation(-4deg);
      margin-top: -20px;
      margin-left: 20px;
    }

    &:nth-child(2) {
      @include rotation(3deg);
      margin-top: 50px;
      margin-left: -10px;

      @include media-breakpoint-down(lg) {
        display: none !important;
      }
    }

    &:nth-child(3) {
      @include rotation(-4deg);
      margin-top: 110px;
      margin-left: 40px;

      @include media-breakpoint-down(lg) {
        display: none !important;
      }
    }
  }

  &__steps {
    display: flex;
    flex-direction: column;

    img {
      &:nth-of-type(1) {
        z-index: 100;
      }
      &:nth-of-type(2) {
        @include rotation(90deg);
        width: 200px;
        margin: 20px 0 20px -100px;
      }

      &:nth-of-type(4) {
        margin-top: 200px;
        z-index: 100;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .howItWorks {
    &__dog {
      &:nth-child(1) {
        margin-left: 10px;
      }
      &:nth-child(2) {
        margin-left: -40px;
      }
      &:nth-child(3) {
        margin-top: 90px;
        margin-left: 35px;
      }
    }
  }
}

.humanGrade {
  right: 28%;
  top: 28%;
  position: absolute;
}

@include media-breakpoint-up(md) {
  .humanGrade {
    right: 35%;
  }

  .howItWorks {
    &__steps {
      img {
        &:nth-of-type(2) {
          @include rotation(90deg);
          width: 150px;
          margin: 20px 0 20px -70px;
        }

        &:nth-of-type(4) {
          margin-top: 150px;
          z-index: 100;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .humanGrade {
    right: 40%;
  }

  .howItWorks {
    &__steps {
      img {
        &:nth-of-type(2) {
          @include rotation(90deg);
          width: 120px;
          margin: 20px 0 20px -50px;
        }

        &:nth-of-type(4) {
          margin-top: 110px;
          z-index: 100;
        }
      }
    }
  }
}
@include media-breakpoint-up(xl) {
  .humanGrade {
    right: 43%;
  }

  .howItWorks {
    &__steps {
      img {
        &:nth-of-type(2) {
          @include rotation(90deg);
          width: 95px;
          margin: 20px 0 20px -30px;
        }

        &:nth-of-type(4) {
          margin-top: 80px;
          z-index: 100;
        }
      }
    }
  }
}

.dot {
  position: relative;
}

.dot1 {
  top: 1px;
  left: 2px;
}

.dot2 {
  // top: 16px;
  left: 14px;
}

.dot3 {
  left: 2px;
}

.dot4 {
  left: 14px;
}

.line1 {
  user-select: none;
  pointer-events: none;
  position: absolute;
  left: -25px;
  top: 55px;
  width: 100px;
  height: 90px;
  transform: rotate(-90deg);

  @include media-breakpoint-down(lg) {
    left: -5px;
  }
}

.line2 {
  user-select: none;
  pointer-events: none;
  position: absolute;
  left: -25px;
  top: 55px;
  width: 100px;
  height: 90px;
  transform: rotate(-90deg);
  @include media-breakpoint-down(lg) {
    left: -5px;
  }
}

.line3 {
  user-select: none;
  pointer-events: none;
  position: absolute;
  left: -10px;
  top: 220px;
  width: 100px;
  height: 90px;
  transform: rotate(90deg);
  @include media-breakpoint-down(lg) {
    left: 5px;
    top: 215px;
  }
}

.stickyArea {
  top: 100px;
  position: -webkit-sticky;
  position: sticky;

  @include media-breakpoint-down(lg) {
    top: -350px;
  }
}

.deliveryBadge {
  z-index: 3;
  position: absolute;
  right: 15%;
  top: -2%;
  @include media-breakpoint-down(sm) {
    right: 35%;
    top: 0;
    width: 100px;
  }
}
.details {
  line-height: 20px;
  font-size: 14px;

  @include media-breakpoint-up(lg) {
    line-height: 22px;
    font-size: 16px;

  }
}
