@import '../../../styles/colors.scss';
@import '../../../styles/bootstrap-imports.scss';
@import '../../../styles/bg';

.dropdownMenu:hover {
  background-color: #FFFFFF;
}

.dropdownMenuContent {
  background-color: #FFFFFF;
  text-align: center;
}

@include media-breakpoint-down(md) {
  .navbarOpen {
    background-color: #fdf6e9;
    background-attachment: fixed;
  }

  .navbarClose {
    height: 77px;
  }
}

@media (max-width: 650px) {
  .navbarContainer {
    margin-top: 60px !important;
  }
}