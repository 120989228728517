@import 'src/client/styles/bootstrap-imports.scss';
@import 'src/client/styles/colors.scss';

.button {
  min-width: 168px;
  font-size: 18px;
  font-weight: 700;
  border-radius: 50rem !important;

  &:disabled {
    $disabled-grey: #e9e5e0;
    background-color: $disabled-grey;
    color: white;
    border: solid 1px $disabled-grey;
  }
}

