@import '../../styles/colors.scss';
@import '../../styles/bootstrap-colors.scss';
$track-color: #fff !default;
$thumb-color: $text-secondary !default;

$thumb-radius: 24px !default;
$thumb-height: 40px !default;
$thumb-width: 40px !default;
$thumb-shadow-size: 4px !default;
$thumb-shadow-blur: 4px !default;
$thumb-shadow-color: rgba(0, 0, 0, 0.2) !default;
$thumb-border-width: 2px !default;
$thumb-border-color: darken($thumb-color, 6%) !default;

$track-width: calc(100% - 4px) !default;
$track-height: ($thumb-height + 4) px !default;
$track-shadow-size: 1px !default;
$track-shadow-blur: 1px !default;
$track-shadow-color: rgba(0, 0, 0, 0.1) !default;
$track-border-width: 2px !default;
$track-border-color: $secondary !default;

$track-radius: 35px !default;
$contrast: 5% !default;

$ie-bottom-track-color: darken($track-color, $contrast) !default;

@mixin shadow($shadow-size, $shadow-blur, $shadow-color) {
  box-shadow: $shadow-size $shadow-size $shadow-blur $shadow-color,
    0 0 $shadow-size lighten($shadow-color, 5%);
}

@mixin track {
  cursor: default;
  height: $track-height;
  transition: all 0.2s ease;
  width: $track-width;
  padding: 2px;
}

@mixin thumb {
  // @include shadow($thumb-shadow-size, $thumb-shadow-blur, $thumb-shadow-color);
  background-color: $thumb-color;
  background-image: url('/svgs/slider.svg');
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: $thumb-radius;
  box-sizing: border-box;
  cursor: default;
  height: $thumb-height;
  width: $thumb-width;
}



.slider {
  -webkit-appearance: none;
  background: transparent;
  margin: $thumb-height / 4 0;
  width: $track-width;

  &::-moz-focus-outer {
    border: 0;
  }

  &:focus {
    outline: 0;

    &::-webkit-slider-runnable-track {
      background: lighten($track-color, $contrast);
    }

    &::-ms-fill-lower {
      background: $track-color;
    }

    &::-ms-fill-upper {
      background: lighten($track-color, $contrast);
    }
  }

  &::-webkit-slider-runnable-track {
    @include track;
    // @include shadow($track-shadow-size, $track-shadow-blur, $track-shadow-color);
    background: $track-color;
    border: $track-border-width solid $track-border-color;
    border-radius: $track-radius;
  }

  &::-webkit-slider-thumb {
    @include thumb;
    -webkit-appearance: none;
    margin-top: ((-$track-border-width * 2 + $track-height) / 2 - $thumb-height / 2);
  }

  &::-moz-range-track {
    @include shadow($track-shadow-size, $track-shadow-blur, $track-shadow-color);
    @include track;
    background: $track-color;
    border: $track-border-width solid $track-border-color;
    border-radius: $track-radius;
    height: $track-height / 2;
  }

  &::-moz-range-thumb {
    @include thumb;
  }

  &::-ms-track {
    @include track;
    background: transparent;
    border-color: transparent;
    border-width: ($thumb-height / 2) 0;
    color: transparent;
  }

  &::-ms-fill-lower {
    @include shadow($track-shadow-size, $track-shadow-blur, $track-shadow-color);
    background: $ie-bottom-track-color;
    border: $track-border-width solid $track-border-color;
    border-radius: ($track-radius * 2);
  }

  &::-ms-fill-upper {
    @include shadow($track-shadow-size, $track-shadow-blur, $track-shadow-color);
    background: $track-color;
    border: $track-border-width solid $track-border-color;
    border-radius: ($track-radius * 2);
  }

  &::-ms-thumb {
    @include thumb;
    margin-top: $track-height / 4;
  }

  &:disabled {
    &::-webkit-slider-thumb,
    &::-moz-range-thumb,
    &::-ms-thumb,
    &::-webkit-slider-runnable-track,
    &::-ms-fill-lower,
    &::-ms-fill-upper {
      cursor: not-allowed;
    }
  }
}


.controllerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-top: 15px;
}

.label {
  color: $secondary;
  position: absolute;
  top: -10px;
  font-weight: 700;
}

.footerSlider {
  &::-webkit-slider-runnable-track {
    border: none;
  }

  &::-moz-range-track {
    border: none;
  }
}

.inputWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap:10px;
}