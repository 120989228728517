.dropdown {
  position: relative;
  display: inline-block;
}

.content {
  display: none;
  position: absolute;
  width:100%;
  z-index: 1;
}

.dropdown:hover .content {
  display: block;
}

.dropdown:hover {
  background-color: #FFFFFF;
}