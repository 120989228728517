@import '../styles/colors.scss';
@import '../styles/bootstrap-imports.scss';

@mixin rotation($rotation) {
  transform: rotate($rotation);
  -ms-transform: rotate($rotation);
  -moz-transform: rotate($rotation);
  -webkit-transform: rotate($rotation);
}

.bannerWrapper {
  flex-direction: column;

  @include media-breakpoint-up(lg) {
    flex-direction: row;
  }
}

.bowlBanner {
  @include rotation(0deg);
  overflow: hidden;
  display: flex;
  margin-bottom: 50px;
  margin-top: 50px;
  @include rotation(-1deg);

  @include media-breakpoint-up(md) {
  }
}

@include media-breakpoint-up(md) {
  .humanGrade {
    right: 30%;
    width: 140px;
  }
}

.bestFood {
  &__header {
    color: $default-green;
    font-weight: 900;
    font-size: 50px;
    @include rotation(-3deg);
    text-align: center;
    line-height: 1.1em;
    // margin-top: 266px;
    margin-bottom: 60px;
  }

  &__dashedUnderscore {
    width: 200px;
    margin-top: -50px;
    margin-left: 19%;
  }
}

@media (min-width: 600px) {
  .bestFood {
    &__header {
      margin-top: 370px;
    }
    &__dashedUnderscore {
      margin-top: -60px;
      width: 300px;
    }
  }
}

@media (max-width: 1000px) {
  .doggos {
    height: 370px;
  }
}

@include media-breakpoint-up(md) {
  .bestFood {
    &__header {
      margin-top: 0;
      margin-left: -17%;
    }
    &__dashedUnderscore {
      width: 50%;
      margin-top: -50px;
    }
  }
}
@include media-breakpoint-up(lg) {
  .bestFood {
    &__header {
      margin-top: 140px;
      margin-left: -20%;
      font-size: 82px;
    }
    &__dashedUnderscore {
      margin-top: -69px;
      margin-left: 22%;
      width: 48%;
    }
  }
}
@include media-breakpoint-up(xl) {
  .bestFood {
    &__header {
      margin-left: -27%;
    }
  }
}


.doggos {
  width: 100%;
  // margin-top: -200px;
}

.dog {
  position: absolute;
  top: 0;
  left: -90px;
  right: -100px;
  bottom: -100px;
  width: 320px;
  margin: 0 auto;

  &:nth-child(1) {
    @include rotation(2deg);
    margin-top: 120px;
    margin-left: 75px;
    display: none;
  }

  &:nth-child(2) {
    @include rotation(-2deg);
    margin-left: 445px;
    @media (max-width: 560px){
      margin-left: 38%;
    }
    display: none;
  }

  &:nth-child(3) {
    @include rotation(2deg);
    margin-top: 10px;
  }
}

@include media-breakpoint-up(lg) {
  .dog {
    max-width: 580px;
    left: -40px;
    width: 100%;

    &:nth-child(1) {
      display: block;
    }

    &:nth-child(1) {
      display: none;
    }

    &:nth-child(2) {
      margin-top: 80px;
      @include rotation(-1deg);
      margin-left: 140px;
      display: none;
    }

    &:nth-child(3) {
      @include rotation(4deg);
      margin-left: 40px;
      margin-top: 40px;
    }
  }
}

.howItWorks {
  height: 500px;

  @include media-breakpoint-down(lg) {
    height: auto;
  }

  position: relative;
  overflow: visible;

  &__dog {
    position: absolute;
    z-index: 1;
    max-width: 90%;
    &:nth-child(1) {
      @include rotation(-4deg);
      margin-top: -20px;
      margin-left: 20px;
    }
    &:nth-child(2) {
      @include rotation(3deg);
      margin-top: 50px;
      margin-left: -10px;
    }
    &:nth-child(3) {
      @include rotation(-4deg);
      margin-top: 110px;
      margin-left: 40px;
    }
  }

  &__steps {
    display: flex;
    flex-direction: column;

    img {
      &:nth-of-type(1) {
        z-index: 100;
      }
      &:nth-of-type(2) {
        @include rotation(90deg);
        width: 200px;
        margin: 20px 0 20px -100px;
      }

      &:nth-of-type(4) {
        margin-top: 200px;
        z-index: 100;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .howItWorks {
    &__dog {
      &:nth-child(1) {
        margin-left: 10px;
      }
      &:nth-child(2) {
        margin-left: -40px;
      }
      &:nth-child(3) {
        margin-top: 90px;
        margin-left: 35px;
      }
    }
  }
}

.humanGrade {
  right: 24%;
  top: 28%;
  width: 90px;
  position: absolute;
}

@include media-breakpoint-up(md) {
  .humanGrade {
    width: 140px;
    right: 30%;
  }
}

@include media-breakpoint-up(lg) {
  .humanGrade {
    width: 125px;
    right: 30%;
  }
}
@include media-breakpoint-up(xl) {
  .humanGrade {
    width: 125px;
    top: 30%;
    right: 34%;
  }
}
.bowlSubtitle {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}

@include media-breakpoint-up(lg) {
  .bowlSubtitle {
    line-height: 22px;
  }
}

.ourMenuSubtitle {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
}
@include media-breakpoint-up(lg) {
  .ourMenuSubtitle {
    line-height: 24px;
  }
}


