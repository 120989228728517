@import '../../../styles/bootstrap-imports.scss';

.wrapper {
  height: 430px;
}

@media (min-width: 370px) {
  .wrapper {
    height: 380px;
  }
}
@media (min-width: 420px) {
  .wrapper {
    height: 350px;
  }
}

@include media-breakpoint-up(md) {
  .wrapper {
    height: 340px;
  }
}