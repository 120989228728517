@import './bootstrap-colors.scss';
@import './bootstrap-imports.scss';

@mixin bgPrimaryColor {
  background-color: $background-primary;
  overflow: hidden;
}

.bg {
  &-snack {
    @include bgPrimaryColor();
    background-image: url('/images/snack-bg.png');
    background-position: center;
    background-size: auto 700px;
  }

  &-snack--fixed {
    background-attachment: fixed;
    background-color: $background-primary;
    background-image: url('/images/snack-bg.png');
  }

  &-dotted {
    background-size: cover;
    @include bgPrimaryColor();
    background-image: url('/images/dotted-bg.png');
  }

  &-dotted-orange {
    background-size: cover;
    background-image: url('/images/dotted-bg-orange.png');
    background-color: $primary;
  }

  &-stripes-vertical {
    background-size: cover;
    @include bgPrimaryColor();
    background-image: url('/images/vertical-lines-bg.png');
  }
  &-stripes-horizontal {
    background-size: cover;
    @include bgPrimaryColor();
    background-image: url('/images/horizontal-lines-bg.png');
  }

  &-green {
    background-color: #144a3d;
  }

  &-dotted-green {
    background-size: cover;
    background-color: #01493b;
    background-image: url('/images/dotted-bg-green.png');
  }

  &-lightgreen {
    background-color: #eaf2e6;
  }

  &-footer {
    background-image: url('/images/footer-poster.png');
    height: 500px;
    background-size: cover;
  }

  &-purple {
    background-color: $purple;
  }

  &-white {
    background-color: white;
  }

  &-orange {
    background-color: #f5e7dc;
  }

  &-paper {
    background: url('/images/choose-flavor/dog-diet-bg.webp');
  }

  &-gray {
    background-color: #f7f7f7;
  }
  
  &-faq-process {
    
    background-color: #fafafa;
  }
}

.bg-cover {
  background-size: cover !important;
}

@media (min-width: 2000px) {
  .bg {
    &-snack {
      background-size: 80%;
    }

    &-dotted {
      background-size: 80%;
    }

    &-dotted-orange {
      background-size: 80%;
    }

    &-stripes-vertical {
      background-size: 80%;
    }
    &-stripes-horizontal {
      background-size: 80%;
    }

    &-dotted-green {
      background-size: 80%;
    }
  }
}
