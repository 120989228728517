@import '../../../styles/colors.scss';
@import '../../../styles/bootstrap-imports.scss';

@mixin rotation($rotation) {
  transform: rotate($rotation);
  -ms-transform: rotate($rotation);
  -moz-transform: rotate($rotation);
  -webkit-transform: rotate($rotation);
}

.collapseToggler {
  font-size: 20px;
  color: $text-secondary;
  &:hover {
    color: $text-secondary !important;
  }

  &:active,
  &:focus {
    outline: 0 !important;
    box-shadow: none;
  }
}

.collapseBody {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-width: 0;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 14px;
  line-height: 22px;
}

.wrapper {
  box-shadow: 0 5px 15px rgba(lightgray, 70%);
  border-radius: 20px;
  color: $text-secondary;
  background: #fff;
}

.arrow {
  transition: transform ease 0.3s;
  padding-top: 7px;
  @include rotation(90deg);
}

.arrowActive {
  transition: transform ease 0.3s;
  @include rotation(-90deg);
}

@include media-breakpoint-down(md) {
  .collapseToggler {
    font-size: 16px;
  }
}