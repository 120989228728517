@import '../../../styles/colors.scss';
@import '../../../styles/bootstrap-imports.scss';

// .cloud {
//   height: 300px;
//   background-image: url('/svgs/miska-calc.svg');
//   background-size: 90%;
//   background-repeat: no-repeat;

//   @include media-breakpoint-down(lg) {
//     background-image: url('/svgs/piesek-miska.svg');

//   }
// }

@include media-breakpoint-up(md) {
  .cloud {
    background-size: 70%;
  }
}
@include media-breakpoint-up(lg) {
  .cloud {
    background-size: 84%;
  }
}

.sliderCalculatorCap {
  margin-top: -2px;
  width: 100%;
  overflow: hidden;
  border-top-left-radius: 40% -5%;
  border-top-right-radius: 40% -5%;
}

.price {
  height: 300px;
  padding-left: 57%;
}

@media (min-width: 370px) {
  .price {
    padding-left: 50%;
  }
}

@media (min-width: 410px) {
  .price {
    padding-left: 45%;
  }
}

@media (min-width: 430px) {
  .price {
    padding-left: 35%;
  }
}

@include media-breakpoint-up(md) {
  .price {
    padding-left: 22%;
    padding-top: 20px;
  }
}

@include media-breakpoint-up(lg) {
  .price {
    padding-top: 190px;
    height: auto;
    padding-left: 20%;
  }
}
@include media-breakpoint-up(xl) {
  .price {
    padding-top: 140px;
    padding-left: 12%;
  }
}
