@import 'src/client/styles/bootstrap-colors.scss';
@import "src/client/pages/home.module";

.textGray550 {
  color: $gray-550;
};

.modalDialog {
  @include media-breakpoint-down(md) {
    margin: 0;
  }
}

.modalContent {
  border-radius: 48px;
  background-image: url('/svgs/pieski-calc.svg');
  background-repeat: no-repeat;
  background-position: left -70px bottom;
  background-size: 20%;

  @include media-breakpoint-down(md) {
    background-position: right bottom;
    background-size: 40%;
    border-radius: 0;
    height: 100vh;
    height: 100dvh;
    margin: 0;
    max-width: none;
    width: 100vw;
  }
}

.header {
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 32px;
  text-align: center;
  width: 100%;
}

.closeButton {
  background-color: transparent;
  border-radius: 50%;
  border: none;
  color: $gray-700;
  cursor: pointer;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1;
  margin: 0;
  opacity: .5;
  outline: none;
  padding: 0;
  position: absolute;
  right: 48px;
  text-shadow: 0 1px 0 #fff;
  top: 32px;
  transition: opacity .15s linear;

  &:hover {
    opacity: .75;
  }

  @include media-breakpoint-down(md) {
    opacity: .75;
    right: 32px;
  }

}

.stepImg {
  margin: 0 8px 0 16px
}

.stepLabel {
  color: $gray-550;
  font-size: 16px;
  font-weight: bold;
  padding: 0 16px 0 8px;
}

.modalBody {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.image {
  @include rotation(4deg);

  @include media-breakpoint-down(md) {
    display: none;
  }
}

.letsMeet {
  &__header {
    @include rotation(-3deg);
    color: $text-03;
    font-size: 50px;
    font-weight: 900;
    line-height: 1.1em;
    text-align: center;
  }

}

.progressSteps {
  display: flex;
  flex-direction: row;
  padding: 32px 0;

  @include media-breakpoint-down(md) {
    display: none;
  }
}

.nextButtonDesktop {
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.nextButtonMobile {
  display: none;

  @include media-breakpoint-down(md) {
    bottom: 0;
    display: block;
    padding: 1.5rem;
    position: absolute;
    width: 100%;
  }
}

.nextButtonFullWidth {
  @include media-breakpoint-down(md) {
    width: 100%;
  }
}

.hideOnMobile {
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.hideOnDesktop {
  @include media-breakpoint-up(md) {
    display: none;
  }
}