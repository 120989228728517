@import '../../../styles/colors.scss';
@import '../../../styles/bootstrap-imports.scss';

.dogItem {
  word-wrap: break-word;
  overflow: hidden;
  width: 90%;
  margin: 0 auto;
  background-color: white;
  color: $text-secondary;
  border-radius: 16px;
  text-align: center;
  font-size: 16px;
  box-shadow: 0px 5px 15px 0px rgb(0 0 0 / 8%);

  &__image {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    width: 100%;
  }
}

@include media-breakpoint-down(md) {
  .dogItem {
    width: 250px;
    display: inline-block !important;

    margin: 20px 10px;
  }
}
