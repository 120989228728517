@import '../../../styles/colors.scss';

@mixin dot {
  z-index: 120;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin: 8px;
}

.indicators {
  display: flex;
  flex-direction: row;
  justify-content: center;

  &__item {
    @include dot;
    background-color: lightgray;
  }
  &__itemRecipe {
    @include dot;
    background-color: rgba(255, 255, 255, 0.5);
  }
}

.active {
  z-index: 120 !important;
  background-color: $text-secondary;
}

.activeRecipe {
  z-index: 120 !important;
  background-color: $color-sand;
}
