@import '../../../styles/bootstrap-colors.scss';

.input {
  font-weight: 700;
  border-color: #ccc2b4;
  color: $text-secondary;
  
  &::placeholder {
    font-weight: 400;
  }
  
  &:focus {
    border-color: #ccc2b4;
    color: $text-secondary;
  }
}