@import '../../../styles/colors.scss';
@import '../../../styles/bootstrap-imports.scss';

@mixin myNavItemBase {
  font-weight: bold;
  text-align: center;
  cursor: pointer;
}

.myNavItem {
  @include myNavItemBase;
  color: $text-03 !important;
  padding: 16px 0 14px;
  
  &:hover {
    color: $color-primary !important;
  }
  
  &Login {
    @include myNavItemBase;
    padding: 16px 0 14px;
    color: $color-primary !important;
    
    &:hover {
      color: $text-03 !important;
    }
  }
}

@include media-breakpoint-down(md) {
  .myNavItem {
    @include myNavItemBase;
    font-size: 30px;
    padding-top: 8px;
    padding-bottom: 8px;

    &Login {
      font-size: 30px;
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }
}

.hoverable:hover {
  color: darken($color: $text-03, $amount: 10%);
}

.hoverableLogin:hover {
  color: $color-primary-hover;
}
