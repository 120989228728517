@import '../../../../../styles/bootstrap-imports.scss';

.logoWrapper {
  display: flex;
  justify-content: center;
  flex: 5;
  padding-top: 15px;
  padding-bottom: 15px;

  @include media-breakpoint-down(md) {
    width: 118px;
  }
}
