@import '../../../styles/colors.scss';
@import '../../../styles/bootstrap-imports.scss';

@mixin destyleButton {
  padding: 0;
  background-color: transparent;
  border: transparent;

}

.bar {
  position: fixed;
  bottom: 50px;
  left: 50px;
  padding: 25px 15px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background: #ff7b44;
  z-index: 9999999999!important;

  @include media-breakpoint-down(md) {
    padding: 15px 0 15px 15px;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__cookie {
    position: absolute;
    width: 126px;
    height: 126px;
    bottom: 20px;
    left: -35px;
    @include media-breakpoint-down(md) {
      left: 0;
      top: -70px;
      width: 80px;
      height: 80px;
    }
  }

  &__text {
    position: relative;
    font-size: 32px;
    padding-left: 100px;
    color: white;
    line-height: 1.6;

    @include media-breakpoint-down(md) {
      font-size: 28px;
      padding-left: 0;
    }
  }

  &__link {
    margin-top: 15px;
    font-size: 14px;
    cursor: pointer;
  }

  &__close {
    box-sizing: border-box;
    margin: 0 auto;
    cursor: pointer;
    @include destyleButton();
    display: inline-block;

    background: url('/svgs/x_orange.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 35px 35px;

    width: 50px;
    margin-left: 20px;
    height: 35px;
    border-radius: 50%;

    @include media-breakpoint-down(md) {
      top: -10px;
      padding: 1px 10px;
      margin-left: 0;
    }

    position: relative;
    left: -10px;
    top: 0;

    &:hover {
      outline: none;
      border: transparent;
    }
  }
}

@include media-breakpoint-up(lg) {
  .bar {
    width: 95%;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    bottom: 10px;
  }
}
