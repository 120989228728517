.link {
  cursor: pointer;
  font-weight: 700;

  &:hover {
    text-decoration: underline;
  }
}

.tada {
  margin-right: 12px;
}

.discountButton {
  font-weight: 700;
  font-size: 16px;;
  cursor: pointer;
  color: white;
  background-color: transparent;
  border: none;
  text-decoration: underline;
}